import { AvailableSlotsResponse } from 'types/app/availableSlots';
import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getSlotDetailsByRange = async (
  start_timestamp: number,
  end_timestamp: number,
  guests?: number,
  reservation_id?: number,
): Promise<AvailableSlotsResponse> => {
  return await asyncRequest({
    method: 'GET',
    params: {
      start_timestamp,
      end_timestamp,
      guests,
      reservation_id,
    },
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.AVAILABLE_SLOTS.SLOTS_DETAILS,
  });
};
