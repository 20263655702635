import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { Client } from 'types/app/clients';

export const getClientCall = async (id: number): Promise<Client> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.CLIENT.ROOT}/${id}`,
    },
    true,
  );
};
