import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { ThirdPartyIntegration } from 'app/pages/RestaurantSettings/MarketingTools/ThirdPartyIntegrations';

export const getRestaurantIntegrationsCall = async (): Promise<ThirdPartyIntegration> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.MARKETING_TOOLS.INTEGRATIONS,
    },
    true,
  );
};

export const createOrUpdateRestaurantIntegrationCall = async (
  data: ThirdPartyIntegration,
): Promise<ThirdPartyIntegration[]> => {
  return await asyncRequest(
    {
      method: data.id ? 'PUT' : 'POST',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.SETTINGS.MARKETING_TOOLS.INTEGRATIONS}/${data.vendor}`,
      data,
    },
    true,
  );
};
